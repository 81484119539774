<template>
  <div class="container blop">
    <div class="row">
      <div class="halt">
        <h1>AGR Verunreinigungen in der Ansaugbrücke</h1>
      </div>
      <div class="row">
        <h3>Worum geht es?</h3>
        <p>
          Bei Dieselmotoren der Volkswagen AG, welche vom Abgasskandal betroffen waren, verschmutzen die Ansaugbrücken verstärkt.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>