<template>
  <div class="jobs-container container blop">
    <div class="row">
      <div class="halt">
        <h1>Stellenangebote</h1>
      </div>
      <div class="row mw-700 flex-center">
        <h2>Kfz-Mechatroniker (m/w/d)</h2>
        <p>
          Wir sind ein familiär geführter Familienbetrieb, der als freie Werkstatt ein breites Spektrum an Kfz-Marken und Typen betreut. Dieses reicht von Oldtimern bis Hybride und von KFZ über leichte Nutzfahrzeuge bis hin zu Wohnmobilen.
        </p>

        <h3>
          Ihre Aufgaben
        </h3>
        <ul class="padding">
          <li>
            Professionelle Diagnose sowie Behebung technischer und elektronischer Defekte 
          </li>
          <li>
            Termingerechte Erfüllung anspruchsvoller Reparatur- und Wartungsarbeiten 
          </li>
          <li>
            Eigenständige Durchführung von Wartungs-, Instandsetzungs- und Reparaturarbeiten von PKW und Transportern 
          </li>
          <li>
            Einbau von Zubehörteilen 
          </li>
        </ul>
        <h3>
          Ihr Profil     
        </h3>
        <ul class="padding">
          <li>
            Sie verfügen über eine abgeschlossene Ausbildung als Kfz-Mechatroniker (m/w/d) mit bereits Berufserfahrung.
          </li>
          <li>
            Automobile sind Ihre Leidenschaft und Sie sind sich nicht zu schade auch "ollen Vehikeln" wieder auf die Reifen zu helfen. Vielleicht sind Sie sogar begeisterter WoMo-Fan?
            Dann sind Sie bei uns genau richtig!
          </li>
          <li>
            Sie sind reifer, erfahrener und möchten nur Teilzeit arbeiten? Auch dann freuen wir uns auf Ihre Bewerbung! 
          </li>
        </ul>

        <h3>
          Wir bieten:
        </h3>
        <ul class="padding">
          <li>
            Eine sichere, unbefristete Anstellung in Vollzeit oder Teilzeit
          </li>
          <li>
            Arbeitszeit: 8 Std. Mo-Fr., Wochenende frei (bei Vollzeit)
          </li>
          <li>
            Mitarbeiter-Rabatt 
          </li>
          <li>
            Preisnachlässe auf Produkte/Dienstleistungen des Unternehmens 
          </li>
          <li>
            kostenlose Getränke
          </li>
        </ul>

        <h3>
          Bewerbung:
        </h3>
        <p>
          Wir arbeiten direkt und unkompliziert: <b>Rufen Sie uns an</b>,
           schreiben Sie ein paar Worte per <b>Email</b> an uns - oder <b>kommen</b> Sie einfach direkt <b>vorbei</b>! 
           Wir freuen uns darauf, Sie kennen zu lernen.
        </p>

        <h3>Kontakt</h3>
        <p>
          Florian Müller <br />
          Tel.: <a href="tel:+49612884322">06128-84322</a><br />
          Mail: <a href="mailto:info@kfz-mwm.de">info@kfz-mwm.de</a><br />
          <br>
          Kfz Meister Werkstatt Müller (MWM)<br />
          Obere Waldstraße 9 <br />
          65232 Taunusstein
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.jobs-container ul {
  list-style-type: circle;
  max-width: fit-content;
}

.jobs-container .mw-700 {
  max-width: 700px;
  margin: auto;
}
</style>